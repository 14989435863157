*,
::after,
::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

@-ms-viewport {
    width: device-width
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

.keyboard{
    background: rgba(23, 24, 28, 1);
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px 8px 16px 8px;
    z-index: 9999;
    left: 0;
}

.btns-keyboard{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    row-gap: 8px;
}

.btn-keyboard{
    background: rgba(29, 30, 34, 1);
    border: 1px solid rgba(255, 255, 255, 1);
    align-items: center;
    height: 37px;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-keyboard{
    background: rgba(29, 30, 34, 1);
}
.input-keyboard-wrapper{
    margin-bottom: 8px;
}

.btn-keyboard span{
    font-family: SF UI Display;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #0056b3;
    text-decoration: underline
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

@font-face {
    font-family: "SF UI Display";
    src: url("./assets/fonts/SFUIDisplay-Medium.woff2") format("woff2"), url("./assets/fonts/SFUIDisplay-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "SF UI Display";
    src: url("./assets/fonts/SFUIDisplay-Semibold.woff2") format("woff2"), url("./assets/fonts/SFUIDisplay-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "SF UI Display";
    src: url("./assets/fonts/SFUIDisplay-Regular.woff2") format("woff2"), url("./assets/fonts/SFUIDisplay-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0;
    padding: 0
}

ul {
    list-style-type: none
}

a {
    text-decoration: none
}

a:hover {
    text-decoration: none
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

html {
    height: 100%
}

body {
    position: relative;
    height: 100%;
    font-family: "SF UI Display", sans-serif;
    background: #0d0f14;
    overflow: hidden
}

* {
    line-height: 100%;
    scrollbar-width: thin;
    scrollbar-color: #2d61eb
}

*::-webkit-scrollbar {
    height: 4px;
    width: 4px
}

*::-webkit-scrollbar-track {
    background: none
}

*::-webkit-scrollbar-thumb {
    background-color: #2d61eb
}

.wrapper {
    position: relative;
    min-height: 100%;
    overflow-x: hidden
}

.left-sidebar-grid {
    display: grid;
    grid-template-columns: 190px auto 320px;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    height: 100vh;
}

.left-sidebar-grid .header {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1
}

.left-sidebar-grid .left-sidebar {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2
}

.left-sidebar-grid .main {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2
}

.left-sidebar-grid .right-sidebar {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2
}

.main {
    position: relative
}

.main-index {
    padding: 16px 0;
    overflow: hidden;
    height: 100vh;
}

.container {
    width: 100%;
    max-width: 1900px;
    padding: 0 20px;
    margin: 0 auto;
}

.preloader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: #111212
}

.preloader__icon {
    width: 70px;
    height: 70px;
    border-top: 2px solid #0051fe;
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid #0051fe;
    border-left: 2px solid rgba(0, 0, 0, 0);
    border-radius: 100%;
    animation: preloader .7s linear infinite
}

.preloader img {
    position: absolute;
    height: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

@keyframes preloader {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.title {
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.subtitle {
    margin-top: 8px;
    color: rgba(255, 255, 255, .2);
    font-size: 16px;
    font-weight: 400
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 8px;
    transition: opacity .3s ease;
    cursor: pointer;
}

.btn_background_blue {
    color: #fff;
    border: 1px solid #2d61eb;
    background: #2d61eb;
    box-shadow: 0px 0px 163px 0px #2D61EB;
}

.btn_background_blue:hover {
    color: #fff;
    opacity: .7
}

.btn_background_white {
    color: #000;
    border: 1px solid #2d61eb;
    background: #fff;
    box-shadow: 0px 0px 130px 0px rgba(255, 255, 255, 0.56);
}

.btn_background_white:hover {
    color: #000;
    opacity: .7
}

.btn_background_gray {
    border-radius: 2px;
    color: #fff;
    background: #1e1f23;
    font-size: 16px
}

.btn_background_gray:hover {
    color: #fff;
    opacity: .7
}

.btn_background_green {
    display: inline-flex;
    align-items: end;
    height: 60px;
    padding: 0 60px 8px 16px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: #3e991e;
    cursor: pointer;
    box-shadow: 0px 0px 163px 0px #3e991e;
}

.btn_background_green:hover {
    color: #fff;
    opacity: .7
}

.btn_red_deposit{
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: #c00;
    box-shadow: 0px 0px 163px 0px #c00;
}

.btn_background_red {
    display: inline-flex;
    align-items: end;
    height: 60px;
    padding: 0 60px 8px 16px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: #c00;
    cursor: pointer;
    box-shadow: 0px 0px 163px 0px #c00;
}

.btn_background_red:hover {
    color: #fff;
    opacity: .7
}

.btn img {
    margin-right: 8px
}

.select-coin {
    position: relative
}

.select-coin__header {
    padding: 12px 16px;
    width: 380px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    background: #17181c;
    cursor: pointer;
    transition: opacity .3s ease
}

.select-coin__header:hover {
    opacity: .7
}

.select-coin__header img {
    height: 24px
}

.select-coin__header span:first-child {
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.select-coin__header span:last-child {
    margin-left: 8px
}

.select-coin__body {
    position: absolute;
    display: none;
    left: 0;
    top: 55px;
    width: 380px;
    /* max-height: 350px; */
    overflow-y: auto;
    z-index: 10
}

.select-coin__body_active {
    display: block
}

.select-coin__item {
    padding: 16px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background .3s ease;
    background: #17181c
}

.select-coin__item:hover {
    background: #292a31
}

.select-coin__item:first-child {
    border-radius: 8px 8px 0 0
}

.select-coin__item:last-child {
    border-radius: 0 0 8px 8px
}

.select-coin__item img {
    height: 24px
}

.select-coin__item span {
    margin-left: 8px;
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.select-deal-time {
    display: none;
    position: absolute;
    padding: 12px;
    width: auto;
    top: -140px;
    border-radius: 8px 8px 8px 0px;
    background: #020305;
    z-index: 5;
    left: -10px;
}

.select-deal-time_active {
    display: block
}

.select-deal-time-wrapper {
    position: relative
}

.select-deal-time__btns {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: auto auto;
    cursor: pointer;
    column-gap: 10px;
    width: 215px;
}

.select-deal-time__btn {
    margin-top: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    width: 96px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 44px;
    background: #17181c;
    transition: opacity .3s ease
}

.select-deal-time__btn:hover {
    color: #fff;
    opacity: .7
}

.label {
    margin-top: 16px;
    margin-bottom: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 500
}

.label:first-child {
    margin-top: 0
}

.deal {
    margin-top: 35px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 570px;
    background: #010309;
    border-radius: 12px;
    gap: 10px;
}

.deal__start {
    width: 230px
}

.deal__middle {
    width: 150px
}

.deal__end {
    height: 138px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 140px;
}

.deal__block {
    width: 100%;
    height: 138px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    background: #17181c
}

.deal__block span {
    color: rgba(255, 255, 255, .2)
}

.deal__block-wrapper {
    margin-top: 4px;
    display: flex;
    align-items: center
}

.deal__block-wrapper div:first-child {
    margin-right: 8px
}

.deal__block-line {
    margin: 12px 0;
    height: 2px;
    width: 100%;
    border-radius: 29px;
    background: rgba(255, 255, 255, .7)
}

.input {
    padding: 8px 16px;
    width: 100%;
    border-radius: 44px;
    background: #17181c;
    outline: none;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.input::placeholder {
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.input-select {
    cursor: pointer;
    transition: opacity .3s ease
}

.input-select:hover {
    opacity: .7
}

.chart {
    /* margin-top: 50px */
}

.chart .tv-lightweight-charts {
    margin: 0 auto
}

.modal {
    position: fixed;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgb(0 0 0 / 86%);
    z-index: 10
}

.modal__body {
    position: relative;
    width: 100%;
    max-width: 380px;
    padding: 16px;
    border-radius: 12px;
    background: #020305;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal__body_w_720 {
    width: 100%;
    max-width: 720px
}

.modal__cross {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: opacity .3s ease
}

.modal__cross:hover {
    opacity: .7
}

.modal__logo {
    text-align: center
}

.modal__title {
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center
}

.modal__title-text {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap
}

.modal__title-line {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 17px;
    background: #17181c
}

.modal__title-line:first-child {
    margin-right: 16px
}

.modal__title-line:last-child {
    margin-left: 16px
}

.modal__text {
    color: #fff;
    font-size: 16px;
    font-weight: 500
}

.modal__text_mt-40 {
    margin-top: 40px
}

.modal__text_mt-12 {
    margin-top: 12px
}

.modal__input_mt-24 {
    margin-top: 24px
}

.modal__input-sum {
    padding-left: 30px
}

.modal__input-num {
    padding-left: 42px
}

.modal__input-wrapper {
    position: relative
}

.modal__input-wrapper::before {
    position: absolute;
    top: 50%;
    left: 16px;
    color: rgba(255, 255, 255, .4);
    font-size: 18px;
    font-weight: 400;
    transform: translate(0, -50%)
}

.modal__input-wrapper-sum::before {
    content: "₽";
    font-size: 14px;
    top: calc(50% + 2px)
}

.modal__input-wrapper-time::before{
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.25' cx='7.99967' cy='8' r='6.66667' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.99967 4C8.36786 4 8.66634 4.29848 8.66634 4.66667V7.72386L10.1377 9.19526C10.3981 9.45561 10.3981 9.87772 10.1377 10.1381C9.8774 10.3984 9.45529 10.3984 9.19494 10.1381L7.52827 8.4714C7.40325 8.34638 7.33301 8.17681 7.33301 8V4.66667C7.33301 4.29848 7.63148 4 7.99967 4Z' fill='white'/%3E%3C/svg%3E");
    left: 10px;
}

.modal__input-wrapper-num::before {
    content: "+7"
}

.modal__link {
    margin-top: 16px;
    display: block;
    color: rgba(255, 255, 255, .2);
    font-size: 18px;
    text-align: center;
    transition: opacity .3s ease
}

.modal__link:hover {
    color: rgba(255, 255, 255, .2);
    opacity: .7
}

.modal__link_color_blue {
    display: inline;
    color: #2d61eb
}

.modal__link_color_blue:hover {
    color: #2d61eb
}

.modal__link_fz_18 {
    font-size: 16px
}

.modal__btns {
    margin-top: 40px
}

.modal__btn {
    margin-bottom: 12px;
    width: 100%;
    text-align: center
}

.modal__btn:last-child {
    margin-bottom: 0
}

.modal__btn-deposit,
.modal__btn-withdrawal {
    margin-top: 12px
}

.modal__blocks {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}

.modal__block {
    
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    height: 130px;
    max-width: 220px;
    border-radius: 8px;
    background: #1d1e22;
    cursor: pointer;
    transition: background .3s ease
}



.modal__block-img {
    margin-bottom: 8px;
    height: 24px;
    width: 24px
}

.modal__block-img_w_140 {
    width: 140px
}

.modal__block-img_w_96 {
    width: 96px
}
.modal__block-img_w_48{
    width: 48px;
}
.modal__block-text {
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.modal__block_active {
    background: #2d61eb;
    box-shadow: 0px 0px 163px 0px #2D61EB;
}

.header {
    padding: 12px 0;
    background: linear-gradient(270deg, #FFF 0%, #FFF 100%);
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.header__logo {
    transition: opacity .3s ease;
    width: 160px;
}

.header__logo:hover {
    opacity: .7
}

.active_deal{
    filter: blur(3px);
}

.header__wrapper {
    display: flex;
    align-items: center
}

.header__btn {
    margin-right: 12px
}

.header__btn:last-child {
    margin-right: 0
}

.header__balance {
    margin-right: 12px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    border-radius: 52px;
    background: #17181c;
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.header__balance_color_gray {
    color: rgba(255, 255, 255, .4);
    font-size: 14px
}

.header__balance-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.header__balance-start {
    margin-right: 40px
}

.header__balance-start img {
    width: 160px
}

.header__avatar {
    position: relative
}

.header__avatar-body {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity .3s ease
}

.header__avatar-body:hover {
    opacity: .7
}

.header__avatar-img {
    margin-right: 12px;
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 100%
}

.header__avatar-modal {
    position: absolute;
    display: none;
    width: 460px;
    top: 75px;
    right: 0px;
    padding: 16px;
    border-radius: 12px 0px 12px 12px;
    background: #020305;
    z-index: 5
}

.header__avatar-modal_active {
    display: block
}

.header__avatar-status {
    padding: 8px 16px;
    width: 100%;
    border-radius: 44px;
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.header__avatar-status_blue {
    background: #2d61eb
}

.header__avatar-status_red {
    background: #c00
}

.left-sidebar {
    padding: 16px;
    width: 100%;
    height: calc(100vh - 68px);
    background: linear-gradient(180deg, rgba(0, 68, 255, 0) 0%, rgba(0, 68, 255, 0.03) 100%), linear-gradient(180deg, #17181C 0%, rgba(23, 24, 28, 0) 100%);
    height: 100%;
}

.left-sidebar__block {
    margin-bottom: 12px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    height: 70px;
    max-width: 220px;
    border-radius: 8px;
    background: #1d1e22;
    cursor: pointer;
    transition: background .3s ease
}

.left-sidebar__block:last-child {
    margin-bottom: 0
}

.left-sidebar__block:hover {
    background: #2d61eb
}

.left-sidebar__block_active {
    background: #2d61eb;
    box-shadow: 0px 0px 163px 0px #2D61EB;
}

.left-sidebar__block-img {
    margin-bottom: 8px;
    height: 24px;
    width: 24px
}

.left-sidebar__block-img_w_140 {
    width: 140px
}

.left-sidebar__block-text {
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.right-sidebar {
    padding: 16px;
    width: 100%;
    height: calc(100vh - 68px);
    background: linear-gradient(180deg, rgba(0, 68, 255, 0) 0%, rgba(0, 68, 255, 0.03) 100%), linear-gradient(180deg, #17181C 0%, rgba(23, 24, 28, 0) 100%);
    height: 100%;
}

.right-sidebar__title h2{
    color: #fff;
    font-size: 18px;
    font-weight: 500
}
.right-sidebar__title .modal__cross{
    display: none;
}
.right-sidebar__subtitle {
    margin-top: 8px;
    color: rgba(255, 255, 255, .2);
    font-size: 16px;
    font-weight: 400
}

.right-sidebar__subtitle_center {
    text-align: center
}

.right-sidebar__btns {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.right-sidebar__btn {
    position: relative;
    width: 140px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.right-sidebar__btn_active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #2d61eb;
    border-radius: 2px
}

.right-sidebar__deals {
    margin-top: 8px
}

.right-sidebar__deals-closed {
    display: none
}

.right-sidebar__deal {
    margin-top: 7px;
    padding: 8px;
    border-radius: 8px;
    background: #1e1f23
}

.right-sidebar__deal:first-child {
    margin-top: 0
}

.right-sidebar__deal-top,
.right-sidebar__deal-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.right-sidebar__deal-bottom {
    margin-top: 8px
}

.right-sidebar__deal-name,
.right-sidebar__deal-percent,
.right-sidebar__deal-time {
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.right-sidebar__deal-time {
    text-align: right;
    width: 80px
}

.right-sidebar__deal-percent span {
    color: rgba(255, 255, 255, .2);
    font-size: 14px;
    font-weight: 400
}

.right-sidebar__deal-money {
    color: #fff;
    font-size: 16px;
    font-weight: 400
}

.right-sidebar__deal-money span {
    color: #3e991e
}

.right-sidebar__deal-money:last-child {
    min-width: 80px;
    text-align: right
}



.user_balance_nav{
    display: flex;
    gap: 12px;
}

.balance_nav{
    border-radius: 52px;
    background: #17181C;
    display: flex;
    padding: 2px 10px;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: opacity .3s ease
}

.balance_nav:hover{
    opacity: .7
}

.summ_sign_balance{
    display: flex;
    gap: 1px;
    align-items: flex-end;
}

.sign_balance{
    color: rgba(255, 255, 255, 0.40);
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 2px;
}

.summ_balance{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.arrow_w {
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(135deg);
    transition: opacity .3s ease,transform .3s ease;
}

.arrow_b {
    width: 6px;
    height: 6px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(135deg);
    transition: opacity .3s ease,transform .3s ease;
}

.header__balance-arrow{
    transition: opacity .3s ease,transform .3s ease;
}

.header__balance-arrow_active{
    transform: rotate(180deg);
}

.user_nav{
    height: 44px;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    transition: opacity .3s ease
}
.user_nav:hover{
    opacity: .7
}
.avatar_user{
    width: 44px;
    align-self: stretch;
    border-radius: 50%;
    background: url("./assets/images/new_avatar.svg"), lightgray 50% / cover no-repeat;
}

.info_user{
    position: absolute;
    border-radius: 12px 0px 12px 12px;
    background: #020305;
    width: 460px;
    padding: 16px;
    top: 60px;
    right: 20px;
    z-index: 999;
    display: none;
}

.avatar_login_user{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.avatar_user_info{
    width: 160px;
    height: 160px;
    border-radius: 160px;
    background: url("./assets/images/new_avatar.svg"), lightgray 50% / cover no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.mb-8{
    margin-bottom: 8px;
}
.login_info_user{
    display: flex;
    flex-direction: column;
}
.login_info_user_data{
    height: 40px;
    padding: 8px 16px;
    align-items: center;
    background: #17181C;
    border-radius: 44px;
    width: 228px;
    color: #fff;
    display: flex;
}

.mt-16{
    margin-top: 16px;
}

.login_id_info_user{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.w-100{
    width: 100%;
}

.blur_login_info{
    background: #2D61EB;
    box-shadow: 0px 4px 163px 0px #2E62EB;
}

.elipse{
    position: absolute;
    background: #2d61eb96;
}

.index_elipse2{
    width: 427px;
    height: 427px;
    flex-shrink: 0;
    border-radius: 427px;
    bottom: -230px;
    left: -136px;
}

.index_elipse4{
    width: 633px;
    height: 633px;
    flex-shrink: 0;
    border-radius: 633px;
    top: -430px;
    right: -231px;
}

.index_elipse3{
    bottom: -65px;
    left: 292px;
    width: 175px;
    height: 175px;
}

.index_elipse5{
    width: 259px;
    height: 259px;
    top: 99px;
    right: -195px;
    display: none;
}
.index_elipse6{
    top: -74px;
    left: -48px;
    width: 259px;
    height: 259px;
    flex-shrink: 0;
    display: none;
}

.filler_shedule{
    filter: blur(234.5px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -9999;
}


.wrapper_login{
    display: flex;
    height: 100vh;
}

.login_r_block{
    width: 510px;
    background: linear-gradient(180deg, rgba(0, 68, 255, 0.00) 0%, rgba(0, 68, 255, 0.03) 100%), linear-gradient(180deg, #17181C 0%, rgba(23, 24, 28, 0.00) 100%), #0B0B0B;
}

.schedule{
    height: 100%;
    flex: 1 0 0;
    padding: 40px;
    overflow: hidden;
    position: relative;
}


.index_login {
    width: 390px;
    padding: 16px;
    align-items: center;
    border-radius: 12px;
    background: #020305;
    background-blend-mode: overlay;
    backdrop-filter: blur(20px);
    position: absolute;
    top: 181px;
    left: 50%;
    transform: translate(-50%, 0);
}

.elipse2{
    width: 427px;
    height: 427px;
    flex-shrink: 0;
    border-radius: 427px;
    bottom: -132px;
    left: -72px;
}

.elipse4{
    width: 633px;
    height: 633px;
    flex-shrink: 0;
    border-radius: 633px;
    top: -405px;
    right: -259px;
}

.elipse3{
    bottom: -65px;
    left: 292px;
    width: 175px;
    height: 175px;
}

.elipse5{
    width: 259px;
    height: 259px;
    top: 99px;
    right: -195px;
}
.elipse6{
    top: -74px;
    left: -48px;
    width: 259px;
    height: 259px;
    flex-shrink: 0;
}

.faq {
    padding: 24px 0
}

.faq__wrapper {
    margin-top: 24px;
    display: flex
}

.faq__start {
    margin-right: 40px;
    width: 250px
}

.faq__block {
    margin-top: 16px;
    border-radius: 8px;
    background: #1d1e22
}

.faq__block:first-child {
    margin-top: 0
}

.faq__block-wrapper {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: opacity .3s ease
}

.faq__block-wrapper:hover {
    opacity: .7
}

.faq__block-header {
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.faq__block-arrow {
    cursor: pointer;
    transition: opacity .3s ease, transform .3s ease
}

.faq__block-arrow:hover {
    opacity: .7
}

.faq__block-arrow_active {
    transform: rotate(180deg)
}

.faq__block-body {
    padding: 0 12px 12px 12px;
    display: none
}

.faq__block-body_active {
    display: block
}

.faq__block-link {
    margin-top: 4px;
    display: block;
    color: rgba(255, 255, 255, .2);
    font-size: 16px;
    font-weight: 400;
    transition: color .3s ease;
    cursor: pointer;
}

.faq__block-link:first-child {
    margin-top: 0
}

.faq__block-link:hover {
    color: #fff
}

.faq__block-link_active {
    color: #fff
}

.faq__end {
    width: 100%;
    max-width: 820px
}

.faq__title {
    margin-top: 40px
}

.faq__title_big {
    font-size: 24px
}

.faq__subtitle {
    margin-top: 8px
}

.reviews {
    padding: 24px 0
}

.reviews__grid {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    gap: 50px
}

.reviews__block {
    padding: 24px;
    border-radius: 8px;
    background: #020304
}

.reviews__block-top {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.reviews__block-name {
    margin-right: 8px;
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.reviews__block-star {
    display: flex;
    align-items: center
}

.reviews__block-star img {
    margin-right: 4px
}

.reviews__block-star span {
    color: #2d61eb;
    font-size: 16px;
    font-weight: 400
}

.reviews__block-line {
    margin: 0 16px;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 17px;
    background: #17181c
}

.reviews__block-date {
    white-space: nowrap;
    color: rgba(255, 255, 255, .2);
    font-size: 16px;
    font-weight: 400
}

.reviews__block-bottom {
    margin-top: 18px
}

.reviews__block-review {
    color: rgba(255, 255, 255, .2);
    font-size: 18px;
    font-weight: 400
}

.history{
    padding: 24px;
}

.history table{
    width: 100%;
    border-spacing: 0 16px;
    border-collapse:inherit;
}


.history table thead tr th,
.history table tbody tr td{
    text-align:start;
    padding: 8px;
}

.history table thead tr th:last-child,
.history table tbody tr td:last-child{
    text-align:end;
}


.history table thead tr{
    height: 37px;
    border-radius: 8px;
    background: #1d1e22;
}

.history table tbody tr
{
    border-radius: 8px;
    background: #1d1e22;
}
.history table tbody tr td{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.history table thead tr th{
    color: rgba(255, 255, 255, 0.40);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.c-green{
    color: #3E991E;
}

.c-red{
    color: #C00;
}
.c-orange{
    color:#db7a3b
}
.history_way img{
    width: 38px;
}

.arrow_active{
    transform: rotate(315deg);
}

/* @media (max-width: 540px){
    .balance_nav{
        display: none;
    }
    .left-sidebar-grid{
        display: block;
    }
    .left-sidebar__blocks{
        display: flex;
        gap: 10px;
    }
    .select-coin__header{
        width: 70%;
    }
} */

.chart{
    /* height: 500px; */
}

.info_deals{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: calc(100vh - 190px);
}

.info_user::-webkit-scrollbar {
    display: none;
}

.information_d{
    border-radius: 8px;
    background: #1E1F23;
    padding: 8px;
    align-items: flex-start;
}

.d-flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sign_info_summ{
    font-family: SF UI Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text_deals{
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

.text_time_shedule{
    color: #747477;
    font-family: SF UI Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.schedule_time{
    display: flex;
    gap: 16px;
    margin-top: 12px;
}

.column__deals{
    align-items: center;
    display: grid;
    grid-template-columns: 33% 33% 33%;
}
.deal-btn-mob{
    display: none;
}
.history table tbody tr tr:first-child{
    display: none;
}

.history table .deckstop{
    display: table-footer-group;
}
.history table .mobile{
    display: none;
}



@media (max-width: 1250px){
    .deal{
        width: 500px;
    }
    .deal__start{
        width: 200px;
    }
    .deal__middle{
        width: 130px;
    }
    .deal__end{
        width: 130px;
    }
    .left-sidebar-grid{
        grid-template-columns: 240px auto 320px;
    }
    .right-sidebar__btn{
        width: 140px;
    }
    .select-deal-time__btn{
        width: 87px;
    }
    .select-deal-time__btns{
        width: 180px;
    }
}


@media (max-width: 1090px){
    .left-sidebar-grid{
        grid-template-columns: 190px auto 260px;
    }
    .right-sidebar__btn{
        width: 110px;
    }
    .select-coin__header{
        width: 300px;
    }
    .deal {
        width: 470px;
    }
    .deal__start {
        width: 170px;
    }
    .login_r_block{
        width: 360px;
    }
    /* .index_login{
        left: 250px;
    } */
    .select-deal-time__btn{
        width: 70px;
    }
    .select-deal-time__btns{
        width: 150px;
    }
}

@media (max-width: 950px){
    .left-sidebar__blocks{
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
    .left-sidebar-grid .left-sidebar{
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
    }
    .left-sidebar-grid .main{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
    }
    .left-sidebar-grid .right-sidebar {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
    }
}

@media (max-width: 840px){
    .login_r_block{
        width: 275px;
    }
    /* .index_login{
        left: 160px;
    } */
}

@media (max-width: 770px){
    .left-sidebar-grid .main {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 3;
    }
    .left-sidebar-grid .right-sidebar {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 4;
        grid-row-end: 4;
    }
    .right-sidebar{
        display: none;
    }
    /* .index_login {
        left: 50%;
        transform: translate(-50%, 0%);
    } */
    .login_r_block {
        width: 45%;
    }
    .history_way img{
        display: none;
    }
    .history table tbody tr td {
        color: #FFF;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

}


@media (max-height: 780px) {
    .deal{
        margin-top: 0px;
    }
  }

  @media (max-height: 750px) {
    .deal__block{
        height: auto;
    }
    .deal__start .label{
        margin-top: 5px;
    }
    .deal__end{
        height: auto;
        gap: 10px;
    }
    .btn_background_green{
        height: 50px;
    }
    .btn_background_red{
        height: 50px;
    }
  }
  .mx-w-none{
    max-width: none;
    cursor: none;
}
@media (min-width: 540px){
    .modal__block:hover {
        background: #2d61eb;
        box-shadow: 0px 0px 163px 0px #2D61EB;
    
    }
}

@media (max-width: 540px){
    .modal__block:not(.modal__deposit-block):hover {
        background: #2d61eb;
        box-shadow: 0px 0px 163px 0px #2D61EB;
    }
    .modal__body{
        top: auto;
        left: auto;
        transform: none;
    }

    .modal__input-wrapper-sum::before{
        top:calc(50% + 0.5px);
    }
    .left-sidebar-grid {
        grid-template-columns: auto;
        grid-template-rows: auto;
        height: auto;
    }

    .deal{
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        gap: 10px;
        justify-content: normal;
        margin-bottom: 20px;
    }
    .deal__start {
        width: 100%;
    }

    .deal__middle {
        width: 100%;
    }
    .deal__end {
        width: 100%;
    }

    .btn_background_green {
        padding: 0px;
        width: 100%;
        align-items: center;
    }
    .btn_background_red{
        padding: 0px;
        width: 100%;
        align-items: center;
    }
    .deal__end{
        gap: 10px;
        flex-direction: row;
        height: auto;
    }
    .history table thead tr th{
        font-size: 14px;
    }
    .info_user{
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .select-deal-time{
        width: 285px;
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .select-deal-time__btn{
        width: 125px;
    }
    .header__logo{
        width: 119px;
    }
    .balance_nav{
        height: 25px;
    }
    .summ_balance{
        font-size: 14px;
    }
    .sign_balance{
        font-size: 11px;
    }
    .user_balance_nav{
        align-items: center;
        gap: 8px;
    }
    .avatar_user{
        height: 24px;
        width: 24px;
    }
    .user_nav{
        height: 25px;
    }
    .header{
        padding: 8px 0px;
    }
    .left-sidebar__block-img{
        width: 16px;
        height: 16px;
    }
    .left-sidebar__block{
        margin-bottom: 0;
        flex-direction: row;
        justify-content: center;
        height: 33px;
        gap: 4px;
    }
    .left-sidebar__block-text{
        font-size: 14px;
    }
    .container{
        padding: 0px 8px;
        position: relative;
    }
    .left-sidebar{
        padding: 16px 8px;
    }
    .left-sidebar__blocks{
        gap: 8px;
    }
    .select-coin__header{
        width: 179px;
        padding: 8px 12px;
    }
    .select-coin__header span:first-child{
        font-size: 14px;
    }
    .text_time_shedule {
        font-size: 14px;
    }
    .schedule_time{
        margin-top: 8px;
    }
    .deal-btn-mob {
        display: flex;
        align-items: center;
        position: absolute;
        right: 8px;
        top: 20%;
        gap: 4px;
        cursor: pointer;
    }
    .deal-btn-mob span{
        color: rgba(255, 255, 255, 1);
        color: rgba(255, 255, 255, 0.4);
        font-family: SF UI Display;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        
    }
    .deal{
        display: grid;
        padding: 12px;
        
    }
    .deal__start{
        grid-column-start: 1;
        grid-row-start: 1;
    }
    .deal__middle{
        grid-row-start: 1;
        min-width: 86px;
    }
    .deal__end{
        flex-direction: column;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
    }
    .label{
        font-size: 12px;
    }
    .input{
        font-size: 12px;
    }
    .deal__block{
        font-size: 12px;
        padding: 8px;
    }
    .btn_background_green{
        justify-content: flex-start;
        padding-left: 8px;
        gap: 4px;
        height: 32px;
    }
    .btn_background_red{
        justify-content: flex-start;
        padding-left: 8px;
        gap: 4px;
        height: 32px;
    }
    .btn{
        font-size: 14px;
    }
    .select-deal-time__btn{
        font-size: 12px;
    }
    .select-deal-time__btns{
        column-gap: 4px;
    }
    .select-deal-time{
        width: 225px;
    }
    .select-deal-time__btn {
        width: 100px;
    }
    .select-coin__header img{
        height: 16px;
    }
    .header__balance-arrow{
        width: 12px;
    }
    .select-coin__item img{
        height: 16px;
    }
    .select-coin__item span{
        font-size: 12px;
    }
    .select-coin__body {
        width: 100%;
        top: 33px;
    }
    .left-sidebar-grid .right-sidebar{
        position: absolute;
        top: 45px;
    }
    .right-sidebar{
        background: #000;
        z-index: 9;
        display: none;
    }
    .right-sidebar__title h2{
        font-size: 14px;
    }
    .right-sidebar__title .modal__cross{
        display: block;
    }
    .right-sidebar__subtitle{
        font-size: 12px;
    }
    .text_deals{
        font-size: 12px;
    }
    .modal__cross{
        width: 16px;
    }
    .right-sidebar.active{
        display: block;
    }
    .info_user{
        width: 304px;
    }
    .avatar_user_info{
        width: 124px;
        height: 124px;
        border-radius: 50%;
    }
    .login_info_user_data.summ_balance{
        width: 116px;
    }
    .modal__blocks{
        flex-direction: column;
    }
    .modal__block{
        height: 88px;
        max-width: none;
    }
    .modal__title-text{
        font-size: 14px;
    }
    .modal__block-text{
        font-size: 14px;
    }
    .faq__wrapper{
        flex-direction: column;
    }
    .faq__start{
        margin-right: 0;
        width: auto;
        margin-bottom: 16px;
    }
    .title {
        font-size: 14px;
    }
    .subtitle {
        font-size: 12px;
    }
    .faq__block-header{
        font-size: 14px;
    }
    .faq__block-link{
        font-size: 12px;
    }
    .reviews__block-name{
        font-size: 14px;
    }
    .reviews__block-date{
        font-size: 12px;
    }
    .reviews__block-review{
        font-size: 12px;
    }
    .reviews__block{
        padding: 12px;
    }
    .reviews__grid{
        grid-template-columns: auto;
        gap: 10px;
    }
    .index_login{
        width: 304px;
    }
    .login_r_block{
        width: 0;
    }
    .modal__link{
        font-size: 14px;
    }
    .btn{
        padding: 8px;
    }
    .modal__logo img{
        width: 119px;
    }

    .history table .deckstop{
        display: none;
    }
    .history table .mobile{
        display: block;
    }
    .history table thead tr{
        display: none;
    }
    .history table tbody tr tr{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .history table tbody tr tr:first-child{
        margin-right: 30px;
        font-family: SF UI Display;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
    }
    .history table tbody tr{
        display: flex;
        margin-bottom: 10px;
        padding: 16px;
    }
    .history table thead tr th:last-child, .history table tbody tr td:last-child{
        text-align: left;
    }
    .history table tbody tr td{
        text-align: left;
    }
    .history_way img{
        display: block;
    }
    .history_way{
        display: flex;
    }

    @media (max-height: 760px) {
        .main-index{
            padding: 4px 0;
        }
        
      }
      @media (max-height: 720px) {
        .main-index{
            padding: 0 0;
        }
      }

}

@media (max-width: 375px){
    .history table tbody tr tr:first-child td:nth-child(4){
        margin-top: 15px;
    }
}
.block_deposit{
    border-radius: 8px;
    padding: 8px;
}



.toastr_popup{
    border: 1px solid;
    border-image-source: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 8px;
    border: 1px;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
}
.text_toastr{
    font-family: SF UI Display;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    margin: auto;
    color: rgba(255, 255, 255, 1);

}

.icon_toastr{
    background: rgba(62, 153, 30, 1);
    box-shadow: 0px 0px 107.4000015258789px 0px rgba(62, 153, 30, 1);
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toastr_info{
    background: rgba(46, 151, 180, 1);
    box-shadow: 0px 0px 107.4000015258789px 0px rgba(46, 151, 180, 1);
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toastr_error{
    background: rgba(204, 0, 0, 1);
    box-shadow: 0px 0px 107.4000015258789px 0px rgba(204, 0, 0, 1);
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_toastr_deal{
    width: 8px;
    background: rgba(45, 97, 235, 1);
    box-shadow: 0px 0px 107.4000015258789px 0px rgba(46, 151, 180, 1);
}

.text_toastr_deal{
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.info_deal_text_toastr{
    background: rgba(23, 24, 28, 1);
    display: flex;
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
}

.text_deal_block_toastr{
    font-family: SF UI Display;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.text_deal_block_toastr_big{
    font-family: SF UI Display;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.info_deal_text_toastr_block{
    display: flex;
    gap: 8px;
    flex-direction: column;
}

  .Toastify__toast{
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
  }
  .Toastify__toast-body{
    padding: 0 !important;
    margin: 0 !important;
  }
  .Toastify__toast-body > div:last-child{
    height: 100%;
    width: 100%;
  }

  .Toastify__toast-theme--light{
    background: none !important;
    box-shadow: none !important;
  }

  @media (min-height: 840px) {
    .deal{
        margin-top: 100px;
    }
    @media (max-width: 540px){
        .deal{
            margin-top: 0;
        }
    }
    
  }